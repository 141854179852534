(function($) {
    $("#butezaeonDelete").click(function(e) {
        var theHREF;
        e.preventDefault();
        theHREF = $(this).attr("href");
        $("#confirmModal").modal("show");
        $("#confirmModalNo").click(function(e) {
            $("#confirmModal").modal("hide");
        });
        $("#confirmModalYes").click(function(e) {
            window.location.href = theHREF;
        });
        return false;
    });

    $("#confirmModalNo").click(function(e) {
        $("#confirmModal").modal("hide");
    });

    $("#confirmModalYes").click(function(e) {
        window.location.href = theHREF;
    });

    $(".simpleTable").stupidtable();

    $("select[multiple!='multiple']").each(function() {
        multi( this , {
            'search_placeholder': 'Rechercher une ligne...'
        });
    });

    $("select[multiple='multiple']").each(function() {
        multi( this , {
            'search_placeholder': 'Rechercher une ligne...'
        });
    });

    $('.kpm-date-picker').each(function() {
        $(this).datepicker({
            language: "fr",
            autoclose: true,
            format: "dd/mm/yyyy"
        });
    });
})(jQuery);
